const translation = {
  "DASHBOARD": "Dashboard",
  "DATAVIEW": "Data View",
  "GEOVIEW": "Geo View",
  "LANDFILL_MANAGEMENT": "Landfill Management",
  "LANDFILL_PERFORMANCE": "Landfill Performance",
  "DEVICE_PERFORMANCE": "Device Performance",
  "COMPANY_MANAGEMENT": "Company Management",
  "DEVICE_LOGS": "Device Logs",
  "ADMIN": "Admin",
  "ERROR_OCCURRED": "An error occurred",
  "ACCOUNT": "Account",
  "LOGOUT": "Logout",
  "MAP_VIEW": "Map View",
  "LIST_VIEW": "List View",
  "TABLE_VIEW": "Table View",
  "ONLINE_DEVICES": "Online Devices",
  "MAINTENANCE_REQUIRED": "Attention Required",
  "NO_MAINTENANCE_REQUIRED": "No Attention Required",
  "THRESHOLD_EXCEEDANCES": "Threshold Exceedances",
  "NO_THRESHOLD_EXCEEDANCES": "No Threshold Exceedances",
  "FIELD_METHANE_FLOW": "Field Methane Flow",
  "TOTAL_METHANE_FLOW": "Total M.Flow",
  "TOTAL_FLOW": "Total Flow",
  "AVERAGE_STATIC_PRESSURE": "Avg. Static Press.",
  "AVERAGE_DIFF_PRESSURE": "Avg. Diff Press.",
  "AVERAGE_SYSTEM_PRESSURE": "Avg. System Press.",
  "AVERAGE_GAS_TEMPERATURE": "Avg. Gas Temp.",
  "LANDFILL_NO_SUMMARY": "There is no summary for this landfill",
  "ZOOM_TO_LANDFILL": "Zoom to Landfill",
  "LANDFILL_OVERVIEW": "Landfill Overview",
  "MANAGE_LANDFILL": "Manage Landfill",
  "OPTIONS": "Options",
  "SELECT_LANDFILL": "Select Landfill",
  "SELECT_DEVICE": "Select Device",
  "SELECT_SUB_DEVICE": "Select Device Group's Device",
  "MANAGE_DEVICE": "Manage Device",
  "CURRENT_DATA": "Current Data",
  "CURRENT_GROUP_DATA": "Group's Current Data",
  "HIDE_GROUP_OVERVIEW": "Hide Group Overview",
  "AVERAGED_DATA": "7-day Average",
  "DEVICE_NO_DATA_N_HOURS": "There are no samples of this device in the last {{ hour }} hours",
  "RECENT_SAMPLES": "Status Code Log",
  "DATE": "Date",
  "STATUS_CODE": "Status Code",
  "DEVICE_CHARTS": "Device Charts",
  "PERFORMANCE_CHARTS": "Performance Charts",
  "BACK_TO_LANDFILL_PERFORMANCE": "Back To Landfill Performance",
  "APIS_CONTRIBUTION": "Apis Contribution",
  "TOTAL_CONTRIBUTION": "Total Contribution",
  "TOTAL": "Total",
  "CONTRIBUTION": "contribution",
  "MMBTU": "mmBTU",
  "RNG_VALUE": "RNG Value",
  "BASELINE_EXPECTED": "Baseline Expected",
  "MEASURED": "Measured",
  "GAIN_LOSS": "Gain/Loss",
  "START_DATE": "Start Date",
  "END_DATE": "End Date",
  "DOWNLOAD": "Download",
  "GENERATE": "Generate",
  "DEVICE_NAME": "Device Name",
  "EXCEEDED_PARAMETERS": "Exceeded Parameters",
  "CURRENT_VALUES": "Current Values",
  "ALERT_PARAMETERS": "Alert Parameters",
  "AUTOMATED_DAILY_REPORTS": "Daily Reports",
  "CONTROL_ALGORITHM": "Control Algorithm",
  "CONTROL_ALGORITHM_DEVICE_CONFIGS": "Control Algorithm Device Configs",
  "CONTROL_ALGORITHM_CONFIGS": "Control Algorithm Configs",
  "CONTROL_ALGORITHM_TEST": "Test Control Algorithm",
  "AUTOMATED_DAILY_REPORT_RECEIVERS": "Daily Report Receivers",
  "OFFLINE_DEVICES": "Offline Devices",
  "LANDFILL_CHARTS": "Landfill Charts",
  "SEARCH": "Search",
  "SEARCH_ON_ALL_LANDFILLS": "Search on all landfills",
  "NO_DEVICES_FOUND": "No devices found.",
  "CREATE_LANDFILL": "Create Landfill",
  "DEVICES": "Devices",
  "GROUP_DEVICES": "Group Devices",
  "DEVICE_GROUPS": "Device Groups",
  "CREATE_DEVICE_GROUP": "Create Device Group",
  "MANAGE_DEVICE_GROUP": "Manage Device Group",
  "DELETE_DEVICE_GROUP": "Delete Device Group",
  "NO_DEVICE": "There are no devices in this landfill",
  "NO_DEVICE_GROUPS": "There are no device groups in this landfill",
  "DEVICE_GROUP_CHARTS": "Device Group Charts",
  "LANDFILL_SETTINGS": "Landfill Settings",
  "LANDFILL_INFO": "Landfill Information",
  "USER_GRANTS": "User Grants",
  "DELETE_LANDFILL": "Delete Landfill",
  "CANCEL": "Cancel",
  "BACK": "Back",
  "CONFIRM": "Confirm",
  "CREATE_DEVICE": "Create Device",
  "UPDATE_DEVICE": "Update Device",
  "DEVICE_MANAGEMENT": "Device Management",
  "DEVICE_INFO": "Device Information",
  "DELETE_DEVICE": "Delete Device",
  "UPDATE_ALERT_PARAMETERS": "Update Alert Parameters",
  "CREATE_COMPANY": "Create Company",
  "COMPANY_INFO": "Company Information",
  "DELETE_COMPANY": "Delete Company",
  "COMPANY_USERS": "Company Users",
  "UPDATE_USER": "Edit User",
  "DELETE_USER": "Delete User",
  "CREATE_USER": "Create User",
  "NO_USERS": "There are no users",
  "API_TOKEN": "API Token",
  "GENERATE_API_TOKEN": "Generate API Token",
  "MANAGE_CLOUD_CONNECTORS": "Manage Cloud Connectors",
  "DELETE_CLOUD_CONNECTOR": "Delete Cloud Connector",
  "CREATE_CLOUD_CONNECTOR": "Create Cloud Connector",
  "ACTIONS": "Actions",
  "DEVICE_ACTIONS": "Device Actions",
  "GROUP_ACTIONS": "Group Actions",
  "LANDFILL_ACTIONS": "Landfill Actions",
  "TAKE_SAMPLE": "Take Sample",
  "CHANGE_STATUS": "Change Status",
  "UPDATE_SCHEDULER": "Update Scheduler",
  "VALVE_ADJUSTMENT": "Valve Adjustment",
  "UPDATE_PROFILE": "Update Profile",
  "CHANGE_PASSWORD": "Change Password",
  "SAVE": "Save",
  "UPDATE_DEVICE_ID": "Update Device ID",
  "IMPORT": "Import",
  "EXPORT": "Export",
  "IMPORT_DATA": "Import Data",
  "ALERT_HISTORY": "Alert History",
  "YES": "Yes",
  "NO": "No",
  "SAMPLE_ATTRIBUTES": {
    "CH4": "Methane",
    "CO2": "Carbon Dioxide",
    "O2": "Oxygen",
    "bal": "Balance",
    "staticPressure": "Static Pressure",
    "diffPressure": "Differential Pressure",
    "systemPressure": "System Pressure",
    "gasTemp": "Gas Temperature",
  },
  "SWITCH_TO_GEOVIEW": "Switch to Geo View",
  "STATUS_HISTORY": "Status History",
  "DEVICE_SETTINGS": "Device Settings",
  "CLOUD_CONNECTORS": "Cloud Connectors",
  "REMOTE_CALIBRATION": "Remote Device Calibration",
  "RUN_SPAN_CALIBRATION": "Run Span Calibration",
  "RUN_ZERO_CALIBRATION": "Run Zero Calibration",
  "SCHEDULE_CALIBRATION": "Schedule Calibration",
  "RESTORE_PREVIOUS_CALIBRATION": "Restore Previous Calibration",
  "CANISTER_PARAMETERS": "Canister Parameters",
  "RAW_CALIBRATION_DATA": "Raw Calibration Data",
  "ERROR_FETCHING_SPAN_CALIBRATION_DATA": "Error fetching span calibration data",
  "ERROR_FETCHING_ZERO_CALIBRATION_DATA": "Error fetching zero calibration data",
  "BEFORE_CALIBRATION": "Before Calibration",
  "AFTER_CALIBRATION": "After Calibration",
  "LATEST_SPAN_CALIBRATION": "Latest Span Calibration",
  "LATEST_ZERO_CALIBRATION": "Latest Zero Calibration",
  "CALIBRATION_LOG_PREV": "Previous Calibration Log",
  "CALIBRATION_LOG_CURR": "Current Calibration Log",
  "CALIBRATION_LOG_FACTORY": "Factory Calibration Log",
  "SCHEDULER_SETTINGS": "Scheduler Settings",
  "SAMPLE_SCHEDULER": "Sample Scheduler",
  "AUTO_CALIBRATION": "Auto Calibration",
  "ACTIVE": "Active",
  "INACTIVE": "Inactive",
  "FAILED": "Failed",
  "SUCCESSFUL": "Successful",
  "VALVE_ADJUSTMENT_HISTORY": "Valve Adjustment History",
  "VALVE_ADJUSTMENT_STATUS_ADJUST": "Valve adjusted to {{ value }}%",
  "VALVE_ADJUSTMENT_STATUS_ALGORITHM": "Valve adjustment request received for the value {{ value }}%",
  "VALVE_ADJUSTMENT_STATUS_USER": "Valve adjustment request received for the value {{ value }}%",
  "VALVE_ADJUSTMENT_STATUS_WELL-PRESS-ADJ": "Well Pressure Adjustment set value to {{ value }}%",
  "MANAGE_KML": "Manage KML Layer",
  "MANAGE_KML_DESC": "Your CAD plans can be uploaded as a KML file. This will allow you to overlay your CAD plans on the map.",
  "CURRENT_KML_LAYER": "Current KML Layer",
  "UPDATE_KML_LAYER": "Update KML Layer",
  "NOTIFICATIONS": "Notifications",
  "ADD_NEW_RECEIVER": "Add New Receiver",
  "ALERT_RECEIVERS": "Alert Receivers",
  "DATA_MANAGER": "Data Manager",
  "BATTERY_HISTORY": "Battery History",
  "REPORTS": "Reports",
  "LANDFILL_REPORTS": "Landfill Reports",
  "SITE_SUMMARY_REPORTS": "Site Summary Reports",
  "CALIBRATION_SUMMARY_REPORTS": "Calibration Summary Reports",
  "LANDFILL_UPTIME_REPORTS": "Landfill Uptime Reports",
  "DEVICE_CALIBRATION_REPORTS": "Device Calibration Reports",
  "ASYNC_OPERATION_STATUS": {
    "IDLE": "Idle",
    "IN_PROGRESS": "In Progress",
    "COMPLETED": "Completed",
    "FAILED": "Failed",
  },
  "NO_SITE_SUMMARY_REPORTS": "There are no site summary reports for this landfill",
  "NO_CALIBRATION_SUMMARY_REPORTS": "There are no calibration summary reports for this landfill",
  "NO_LANDFILL_UPTIME_REPORTS": "There are no landfill uptime reports for this landfill",
  "NO_DEVICE_CALIBRATION_REPORTS": "There are no device calibration report for this device",
  "GENERATE_NEW_REPORT": "Generate New Report",
  "ERROR_PAGE_CONTENT": "Something went wrong. Our team will be notified. You will be redirected to main page",
  "DELETE": "Delete",
  "COMPANY_SETTINGS": "Company Settings",
  "COMPANY_ADMINS": "Company Admins",
  "USER_MANAGEMENT": "User Management",
  "INVITE_USER": "Invite User",
  "REVOKE_ADMIN": "Revoke Admin",
  "UNAUTHORIZE_USER": "Unauthorize User",
  "AUTHORIZE_USER": "Authorize User",
  "LANDFILL_USERS": "Landfill Users",
  "ROLES": {
    "apis-admin": "Apis Admin",
    "apis-technician": "Apis Technician",
    "company-admin": "Company Admin",
    "landfill-manager": "Landfill Manager",
    "landfill-technician": "Landfill Technician",
    "landfill-user": "Landfill User"
  },
  "COMPANIES": "Companies",
  "YOUR_COMPANIES": "Your Companies",
  "PENDING_INVITATIONS": "Pending Invitations",
  "LEAVE_COMPANY": "Leave Company",
  "DENY_COMPANY_INVITE": "Deny Company Invitation",
  "APPROVE_COMPANY_INVITE": "Join Company",
  "NO_PENDING_INVITATIONS": "There are not any pending invitations",
  "NO_COMPANIES": "You are not a member of any company",
  "SEARCH_NAME_OR_MAIL": "Search by name or mail",
  "FILTER_BY_ACTIVITY": "Filter by activity",
  "RESTORE_VALVE_POSITIONS": "Restore Valve Positions",
  "APPROVE": "Approve",
  "DATETIME_GREATER_THAN_NOW": "Selected datetime can't be greater than now",
  "SAMPLE": {
    "GS_1": "Normal Port",
    "AS_1": "Available Port",
    "C8": "Calibration",
  },
  "SAMPLE_ATTR": {
    "CH4": "Methane",
    "CO2": "Carbon Dioxide",
    "O2": "Oxygen",
    "bal": "Balance",
    "flow": "Flow",
    "methaneFlow": "Methane Flow",
    "iF": "Sample inFlow",
    "staticPressure": "Static Pressure",
    "vacH": "Sample vacH",
    "diffPressure": "Diff. Pressure",
    "systemPressure": "System Pressure",
    "gasTemp": "Gas Temperature",
    "valvePosition": "Valve Position",
    "date": "Date"
  },
  "NOTIFICATION_CHANNELS": {
    "email": "Email",
    "sms": "SMS",
    "slack": "Slack"
  },
  "EVENT": "Event",
  "SERVICE_VIEW": "Service View",
  "PHONE_NUMBER_VERIFICATION": "Phone Number Verification",
  "SEND_CODE": "Send Verification Code",
  "VERIFY": "Verify",
  "CONTROL_ALGORITHM_TRIGGERS": {
    "CH4": "Methane",
    "O2": "Oxygen",
    "bal": "Balance",
    "staticPressure": "Static Pressure",
    "gasTemp": "Gas Temperature",
    "EFM": "Energy Flow Maximization",
  },
  "CONTROL_ALGORITHM_SKIPPER": {
    "SYSTEM_PRESSURE_RANGE": "System Pressure Range",
    "INFLOW": "Low inFlow",
    "CH4_DELTA_STOP": "CH4 Delta Stop"
  },
  "CONTROL_ALGORITHM_LIMITER": {
    0: "No",
    1: "Max Open Valve Step",
    2: "Min Open Valve Step",
    3: "Max Close Valve Step",
    4: "Min Close Valve Step",
    5: "Upper Range Limit",
    6: "Lower Range Limit",
  }
}

export default { translation };
